/* Custom styling for Google Maps zoom buttons */
.gm-control-active {
  background: rgba(0, 0, 0, 0.4) !important;
  color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
  width: 30px !important;
  height: 30px !important;
  margin: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin-left: -0px !important;
}

.gm-control-active:hover {
  background: rgba(0, 0, 0, 0.6) !important;
}
.gm-bundled-control > div > div {
  box-shadow: none !important;
  background-color: unset !important;
}
.gm-bundled-control > div > div > div {
  display: none !important;
}

.gm-control-active > img {
  filter: brightness(0) invert(1) !important;
}
.gm-fullscreen-control {
  display: none !important;
}

.gm-style .gm-style-iw-tc {
  display: none !important;
}
.gm-style .gm-style-iw-c {
  background: #ff5722 !important;
  padding-bottom: 12px !important;
  padding-right: 12px !important;
}
.gm-style .gm-style-iw-d {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
