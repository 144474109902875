@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Libre Franklin', sans-serif;
}

:root {
  --primary-main: #1f2041;
  --primary-light: #2b2c5a;
  --primary-50: #f0f4fd;
  --primary-100: #e4e8fb;
  --primary-200: #cedaf7;
  --primary-300: #b0c1f1;
  --primary-400: #90a0e9;
  --primary-500: #7571df;
  --primary-600: #5a5fd1;
  --primary-700: #4a4cbb;
  --primary-800: #3e4195;
  --primary-900: #383877;

  --secondary-main: #fe5411;
  --secondary-light: #ff864c;
  --secondary-dark: #ef3a07;
  --secondary-contrastText: #ffffff;
  --secondary-50: #fff5ed;
  --secondary-100: #ffe8d4;

  --success-main: #12b76a;
  --success-light: #32d583;
  --success-dark: #039855;
  --success-contrastText: #ffffff;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;

  --warning-main: #f79009;
  --warning-light: #fdb022;
  --warning-dark: #dc6803;
  --warning-contrastText: #ffffff;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;

  --error-main: #f04438;
  --error-light: #f97066;
  --error-dark: #d92d20;
  --error-contrastText: #ffffff;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;

  --grey-50: #f9fafb;
  --grey-100: #f2f4f7;
  --grey-200: #eaecf0;
  --grey-300: #d0d5dd;
  --grey-400: #98a2b3;
  --grey-500: #667085;
  --grey-600: #475467;
  --grey-700: #344054;
  --grey-800: #1d2939;
  --grey-900: #101828;
  --grey-A100: #fcfcfd;
  --grey-A200: #0c111d;

  --background-default: #f1f1f8;
  --background-paper: #ffffff;
}

body {
  background-color: var(--background-paper);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d0d5dd transparent;
  scroll-behavior: smooth;
}

// TODO: Remove this when the scrollbar is fixed
// ::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
//   background: white;
// }

// ::-webkit-scrollbar-track {
//   background: white;
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--primary-main);
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

// ::-webkit-scrollbar-thumb:horizontal {
//   background: var(--primary-main);
//   border-radius: 10px;
// }

h1 {
  color: var(--primary-main);
}

$order-values: 1 2 3 4;

@each $value in $order-values {
  .order-#{$value} {
    order: #{$value} !important;
  }
}

@media screen and (max-width: 960px) {
  @each $value in $order-values {
    .tab-order-#{$value} {
      order: #{$value} !important;
    }
  }
}

#imgSection {
  cursor: pointer;
}

#imgSection:hover #saveBtn {
  visibility: visible;
}

#saveBtn {
  visibility: hidden;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.restaurant-card-swipe .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.restaurant-card-swipe .swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 1) !important;
}

.dish-to-try-swipe .swiper-pagination-bullet {
  background-color: rgba(255, 204, 168, 1) !important;
}

.dish-to-try-swipe .swiper-pagination-bullet-active {
  background-color: rgba(255, 134, 76, 1) !important;
}

.dish-to-try-swipe .swiper-pagination {
  position: relative !important;
  margin-top: 45px;
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.restaurant-card-swiper-slide {
  padding: 10px 10px 0px 10px !important;
  border-radius: 30px;
}

.restaurant-card-swiper-slide:hover {
  background-color: #f2f4f7;
}

.tag-chip-component-icon {
  transition: 0.4s ease-in-out !important;
}

.tag-chip-component:hover .tag-chip-component-icon {
  transform: rotateZ(360deg) !important;
}

.tag-chip-component-title {
  max-width: 0 !important;
  overflow: hidden !important;
  transition: max-width 0.8s ease 0s !important;
  display: inline-block !important;
  white-space: nowrap !important;
}

.tag-chip-component:hover .tag-chip-component-title {
  max-width: 1000px !important;
}

.css-7r9cv1-MuiTooltip-tooltip,
.css-1l681go-MuiTooltip-tooltip {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 8px 12px !important;
  box-shadow: 0px 0px 16px -4px #10182814 !important;
}

.css-2xhv9j-MuiTooltip-arrow {
  color: #fff !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html,
body {
  height: 100%;
}
body {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}
